<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
    </div>

    <stock-movement-view-details-product-list
      :stockMovement="stockMovement"
      @stockMovementItemsUpdated="stockMovementItemsUpdated"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import StockMovementViewDetailsProductList from "./StockMovementViewDetailsProductList.vue";

export default {
  name: "stock-movement-view-details",

  components: {
    StockMovementViewDetailsProductList,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: ["stockMovement"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    stockMovementItemsUpdated() {
      this.$emit("stockMovementItemsUpdated");
    },
  },

  mounted() {},

  watch: {},
};
</script>
